import baseTheme from "gatsby-theme-blog/src/gatsby-plugin-theme-ui"
export default {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    text: "rgba(0,0,0,0.9)",
    background: "#FAFAFA",
    primary: "#497ae3",
    secondary: "#F44708",
    modes: {
      dark: {
        text: "#FAFAFA",
        background: "#212121",
        primary: "#63ceff",
        secondary: "#F44708"
      }
    }
  },
  fonts: {
    body: "DM Mono, system-ui, sans-serif",
    heading: "DM Mono, system-ui, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  fontSizes: [12, 14, 14, 20, 24, 32, 48, 64, 72],
  styles: {
    ...baseTheme.styles,
    a: {
      ...baseTheme.styles.a,
      textDecoration: `none`,
    },
    p: {
      ...baseTheme.styles.p,
      marginBottom: `5px`,
    },
    h4: {
      ...baseTheme.styles.h4,
      marginBottom: `10px`,
      fontSize: '16px',
    },
    h3: {
      ...baseTheme.styles.h3,
      marginBottom: `12px`,
      fontSize: '16px',
    },
    li: {
      ...baseTheme.styles.li,
      marginBottom: `5px`,
      listStyleType: `none`,
    },
  }
}